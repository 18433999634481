<template>
	<div class="code-block">
		<div class="code">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: ['code']
}
</script>

<style lang="scss" scoped>
.code-block {
	margin: 2rem 0;
	display: flex;

	.code {
		max-width: 800px;
		background-color: #1e1e1e;
		padding: 1rem;
		border-radius: 6px;
	}
}
</style>