<template>
	<div class="code-element">
		<div>
			<span class="tag-bracket">&lt;</span><span class="tag">{{code.tag}}</span>
		</div>
		<div
			class="attributes-wrapper"
			v-for="(item, index) in code.attributes"
			:key="`attr-${index}`"
		>
			<span class="attribute">{{item.name}}</span>=<span class="value">"{{item.value}}"</span>
		</div>
		<div>
			<span class="tag-bracket">&gt;</span>
		</div>
		<div class="text-wrapper">
			<span class="text">
				{{code.text}}
			</span>
		</div>
		<span class="tag-bracket">&lt;/</span><span class="tag">{{code.tag}}</span><span class="tag-bracket">&gt;</span>
	</div>
</template>

<script>
export default {
	props: ['code']
}
</script>

<style lang="scss" scoped>
$tab-width: 1.5rem;

.code-element {
	color: white;

	.tag {
		color: #568de6;
		font-weight: bold;
	}

	.tag-bracket {
		color: #808073;
		font-weight: bold;
	}

	.attributes-wrapper {
		padding-left: $tab-width;
		word-wrap: break-word;

		.attribute {
			color: #9cdcfe;
		}

		.value {
			color: #ce915b;
		}
	}

	.text-wrapper {
		padding-left: $tab-width;

		.text {
			color: white;
		}
	}
}
</style>