<template>
	<div class="documentation">
		<simulator-header />
		<div class="content">
			<div class="documentation-title">Integração de parceiros Concede</div>
			<template v-if="partnership">
				<documentation-topic>
					<template v-slot:title>
						Introdução
					</template>
					<template v-slot:description>
						Neste documento iremos explicar os parâmetros do componente html e suas
						obrigatoriedades para que os parceiros da Concede possam integrar seus sites com o simulador.
					</template>
				</documentation-topic>
				<documentation-topic>
					<template v-slot:title>
						Código
					</template>
					<template v-slot:description>
						Abaixo segue o html do botão para redirecionar ao simulador da Concede.
						<documentation-code-block>
							<documentation-code-block-element
								v-if="documentation"
								:code="documentation.code"
							/>
						</documentation-code-block>
						Ex.:
						<a
							:href="`https://simulador.concede.vc/#/?partnership=${partnership}&email=${email}&value=25000000&entryValue=5000000&birthDate=2001-01-01&months=300`"
							target="_blank"
							style="background-color: #005FAC; color: white; padding: 10px 32px; text-decoration: none; border-radius: 4px;"
						>
							Fazer Simulação
						</a>
					</template>
				</documentation-topic>
				<documentation-topic>
					<template v-slot:title>
						Parâmetros
					</template>
					<template v-slot:description>
						<p>
							Abaixo segue a descrição de todos os parâmetros esperados na url de redirecionamento.
						</p>
						<div class="params">
							<div class="stripe" />
							<div
								class="content"
								v-if="documentation"
							>
								<div
									class="param"
									v-for="(param, paramIndex) in documentation.params"
									:key="`param-${paramIndex}`"
								>
									<div class="name">{{param.name}}</div>
									<div class="param-paragraph">
										<div class="descriptions">
											<div
												class="description"
												v-for="(desc, descIndex) in param.descriptions"
												:key="`desc-${descIndex}`"
											>
												{{desc}}
											</div>
										</div>
										<div>Tipo: <strong>{{param.type}}</strong></div>
										<div>Obrigatório: <strong>{{param.required}}</strong></div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</documentation-topic>
			</template>
			<template v-else>
				Dados do parceiro não encontrados.
			</template>
		</div>
	</div>
</template>

<script>
import SimulatorHeader from '../components/General/SimulatorHeader.vue';
import documentation from '../assets/js/documentation';
import DocumentationTopic from '../components/Documentation/DocumentationTopic.vue';
import DocumentationCodeBlock from '../components/Documentation/DocumentationCodeBlock.vue';
import DocumentationCodeBlockElement from '../components/Documentation/DocumentationCodeBlockElement.vue';

export default {
	components: { SimulatorHeader, DocumentationTopic, DocumentationCodeBlock, DocumentationCodeBlockElement },
	data () {
		return {
			documentation: null,
			partnership: '',
			email: '',
		}
	},
	mounted () {
		this.documentation = documentation;

		this.getDataFromURL();

		console.log(this.documentation);
	},
	methods: {
		getDataFromURL () {
			const { partnership, email } = this.$route.query;
			this.partnership = partnership;
			this.email = email;

			this.documentation.setRequiredData(this.partnership, this.email);
		}
	}
}
</script>

<style lang="scss" scoped>
strong {
	color: $light-blue;
}

.documentation {
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;

		.documentation-title {
			color: $blue;
			padding: 2rem 0;
			font-size: 2rem;
		}

		.params {
			display: flex;
			margin: 2rem 0;

			.stripe {
				background-color: $blue;
				min-width: 6px;
				border-radius: 4px 0 0 4px;
			}

			.content {
				padding: 1rem;
				background-color: #f4f4f4;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.param {
					margin: 1rem 0;

					.name {
						font-weight: bold;
						font-style: italic;
						color: $blue;
						font-size: 1.125rem;
					}

					.param-paragraph {
						padding-left: 1rem;

						.descriptions {
							margin: 0.5rem 0;
						}
					}
				}
			}
		}
	}
}
</style>