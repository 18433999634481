<template>
	<div class="topic">
		<div class="topic-title">
			<slot name="title" />
		</div>
		<div class="topic-description">
			<slot name="description" />
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.topic {
	width: 800px;
	margin-bottom: 1.5rem;

	.topic-title {
		color: $blue;
		font-size: 1.5rem;
		padding: 1rem 0;
	}

	.topic-description {
		color: black;
	}
}
</style>